import React from 'react';
import {    
    useLocation
  } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Hidden } from '@material-ui/core';
import { ProductOffer } from 'model/interfaces/Model';

const useStyles = makeStyles({
  root: {
    minWidth: 120,
    marginLeft: 10,
    marginRight: 10,    
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cta: {
      backgroundColor: '#feda14'
  }
});
export interface ProductCardDataPrice {
    ytn: number,
    eur: number,
    usd: number,
    rub: number
}
export interface ProductCardData {
    price: ProductCardDataPrice,
    shortText: string,
    longText: string    
}

export interface ProductCardProps {
    product: ProductOffer,
    onOrdered?: Function
}



export default function ProductCard(props: ProductCardProps) {
  const {product, onOrdered} = props;
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  

  return (
    <Card className={classes.root}>
      <CardContent>
        
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Spend {product.price.priceFullFormatted}
        </Typography>
        <Typography variant="h4" component="h2">
        <small>Get </small>{(product.amount/1e8).toFixed(0)}YTN
        </Typography>
        <Typography variant="body1" component="p">
        {product.description.short}
        </Typography>      
        <Hidden mdDown={true}>
            <Typography variant="body2" component="p">
            {product.description.long}
            </Typography>        
        </Hidden>  
        
      </CardContent>
      <CardActions>
        <Button size="small" fullWidth={true} className={classes.cta} onClick={()=>{if(onOrdered) onOrdered(product)}}>Order {(product.amount/1e8).toFixed(2)} YTN</Button>
      </CardActions>
    </Card>
  );
}
