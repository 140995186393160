export const en = {
  application_name: 'PayTip',
  intro_text: 'Convenient cashless tipping app!',
  login_title: 'Sign in',
  email: 'E-mail',
  password: 'Password',
  email_placeholder: 'Enter your e-mail',
  password_placeholder: 'Enter your password',
  login: 'Sign in',
  forgot_password: 'Forgot password ?',
  wrong_data: 'Invalid data',
  login_facebook: 'Sign in via facebook',
  dont_have_account: 'Not having the PayTip account yet?',
  sign_up: 'Sign up',
  sign_in: 'Sign in',
  reset_password_title: 'Password reset',
  reset_password_help: 'Enter your email address so we can send password reset message.',
  reset_password_info: 'Password reset message was sent',
  reset_password: 'Reset password',
  new_update: 'New version has arrived! Reload your app.',
  reload: 'Reload',
  please_verify_email: 'Verify your email please',
  currency: 'zł',
  cookies_msg: 'We may store and access personal data such as cookies, device identifiers or other similar technologies on your device and process such data to personalise content and analyse our traffic. You can read more about this and how to object by Privacy Policy.',
  accept: 'I accept',
  errMessage: 'Drat, something went wrong. Try again.',
  errMessage_blik: 'Ups, please check if BLIK code was entered correctly and try again',
  errMessage_maxTip: 'The maximum tip ammount is',
  errMessage_minTip: 'The minimum tip amount is',
  giveTip: 'Leave the tip',
  invoice_amount: 'Invoice amount',
  tipPlaceholder: 'i.e.: 29.00',
  valueOfTipHeight: 'Enter tip amount',
  entry: 'Continue',
  paymentType: 'Payment',
  choosePaymentType: 'Choose payment method',
  writeBlikCode: 'Enter BLIK code',
  blikCodePlaceholder: ' 6 digits',
  pay: 'Yes, leave the tip',
  pay_retry: 'Retry',
  errMessage_Payment: 'Ups, transaction rejected. Please verify your limits and <br /><br />try again',
  tip_is_ready: 'Tip is served',
  give_qrcode: 'Show this QR code to the person, that will accept the tip',
  error: 'Error',
  give_next_tip: 'Ok, that\'s enough for now',
  in: 'In',
  countdown_text: 'you will be redirected to the homepage.',
  information: 'Information',
  blik_info1: 'Now open your bank application please, and generate the BLIK code',
  blik_info2: 'Now switch to the bank application and please confirm BLIK code',
  blik_info_first_step: 'Buckle up your seatbelts, the tip is coming. Please stand by...',
  desktop_dialog_title: 'Please switch to a mobile device',
  desktop_dialog_content: 'This is an application optimized for a mobile device. Use your camera smartphone to scan QR code to enter mobile page.',
  home: {
    heroAlt: 'Welcome in PayTip',
    helloName: 'Hi,',
    helloNoName: 'Hi!',
    leavePlace: 'Put it</br>into jar',
    leaveQR: 'Leave using<br/>QR code',
    share: 'Share<br/>with friends',
    profile: 'My profile<br/>&nbsp;'
  },
  activate: {
    titleSuccess: 'Well done!',
    title: 'Account activation',
    email: 'Email',
    emailPlaceholder: 'Enter your email',
    code: 'Activation code',
    codePlaceholder: 'Enter activation code (6 digits)',
    proceed: 'Activate',
    message: 'Please enter your activation code to activate your account. The activation code is a 6 digit code that was sent to you via email. Check your mailbox.',
    message2: 'On successfull activation you will be allowed to sign in and start leaving tips.',
    messageSuccess: 'Activation order sent successfully. You may sign in now.',
    login: 'Proceed to login'
  },
  share: {
    message: 'Hello! Tipping would be much more fun using PayTip. Please sign up using my link https://giver.paytip.pl',
    messageSubject: 'Tipping via mobile phone',
    heroAlt: 'Help PayTip grow',
    heroTitle: 'Help PayTip grow!',
    heroMsg: 'Join our efforts to democratize tip giving. With your support this great tipping habit can enter the mobile, cashless era. </br>More users is a chance to spread PayTip faster and promote this modern mean of leaving tips.',
    methodTitle: 'Choose the way',
    methodSubtitle: 'how to tell your friends about PayTip',
    infoText: 'Recipients will receive short message inviting them to join PayTip application.',
  },
  leave: {
    thanks: 'Thanks for tipping',
    explainer: 'Well done ! The tip just reach the jar ',
    explainerCtd: 'Thanks a lot, but wait ... one more thing...',
    gotIt: 'Fine, got it',
    oneMore: 'having one more second, please leave your review.',
    shareOpinion: 'Send review',
    sharePlaceholder: 'The review will be registered on Trip Advisor site.',
    tipboxIntro: 'Tipping jar location',
    reviewThanks: 'Thanks ! Review saved. It will be available on Trip Advisor site soon.',
    ratingLabel: 'Rate the place',
    reviewHosNameIntro: 'Host name: ',
  },
  cityDictionary: {
    warsawAndSuburbs: 'Warsaw and suburbs',
    poznan: 'Poznań',
    krakow: 'Cracow'
  },
  place: {
    pageSubTitle: 'Leaving tip',
    citySelectTitle: 'City',
    citySelectSubTitle: 'Where are you?',
    placeFinderTitle: 'Place',
    placeFinderSubTitle: 'Where to leave the tip?',
    placeFinderPlaceholder: 'Please enter place name',
    nextStep: 'Leave tip',
    tipboxInfo: 'Tip will be left in a common \'tip jar\' which is shared by all place\'s staff.',
    noResults: 'Start typing to find a place'
  },
  profileTitle: 'Profile',
  profile: {
    name: 'Name',
    namePlaceholder: 'Your name',
    nameInvalidMsg: 'Enter name',
    phone: 'Mobile phone',
    phonePlaceholder: 'i.e. 501000100',
    phoneInvalidMsg: 'Enter your mobile phone (9 digits)',
    save: 'Save changes',
    whyData: 'Leave your mobile number so we can reach you faster in case there is unathorized access to your account.',
    whyName: 'In PayTip we call each other by name as we strive to democratize tip giving.',
    operationOk: 'Save successfull.',
    operationErr: 'Drat, something went wrong. Check the data and try again.',
  },
  give: {
    title: 'Leave using QR code',
    explainer: 'Request the host to get ready with his mobile phone device. In following steps the application will generate QR code that contains the tip. Ask the host to scan the tip using his mobile camera.',
    explainer2: 'In case the host can\'t accept the tip right now, you may <a href="/leave/place">leave it in the jar</a>. The host will claim the tip later.',
    amountTitle: 'Enter tip amount'
  },
  give_to_jar: 'Put it into jar',
  start: 'Start',
  logout: 'Sign out',
  give_by_code: 'Leave using QR code',
  logged_out: 'Logged out',
  login_again: 'Log in again',
  error_email: 'Incorrect email address',
  terms_accept_text1: 'I hereby accept',
  terms_accept_text2: 'and grant approval to my personal data being processed.',
  terms_accept_link: 'PayTip regulations and privacy policy',
  sign_up_title: 'Sign Up',
  sign_up_info: 'Activation link has been sent. Please check your mailbox.',
  create_account: 'Create account',
  already_have_account: 'Already have an account?',
  terms: 'Regulations and rules',
  terms_text: `
    <div style="margin-bottom: 20px;">
      <div style="display: inline-block; width: 68%;">
        <a style="color: black" href="https://app.paytip.pl/documents/Regulamin_PayTip_-_15.06.2020.pdf">PayTip Terms of use</a><br />
        <span style="font-size: 0.9rem">15 czerwca 2020 roku</span>
      </div>
      <div style="display: inline-block; width: 30%; text-align: right;">
        <img height="50" style="vertical-align: sub" src="https://app.paytip.pl/img/Adobe-PDF-File-Icon-01.png" />
      </div>
    </div>
    <div style="margin-bottom: 20px;">
      <div style="display: inline-block; width: 68%;">
        <a style="color: black" href="https://app.paytip.pl/documents/Polityka_prywatnosci_-_15.06.2020.pdf">Privacy policy</a><br />
        <span style="font-size: 0.9rem">15 czerwca 2020 roku</span>
      </div>
      <div style="display: inline-block; width: 30%; text-align: right;">
        <img height="50" style="vertical-align: sub" src="https://app.paytip.pl/img/Adobe-PDF-File-Icon-01.png" />
      </div>
    </div>
  `,
  tipHistory: 'Tip history',
  language: 'Language',
  shareWithFriends: 'Share with friends',
};
