import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  cookiesCard: {
    border: `none`,
    '& > div': {
      fontSize: '1.25rem',
      flexWrap: 'inherit',
      padding: '3px 16px',
      color: '#404040',
      background: 'white',
      display: 'grid',
      justifyItems: 'center',
      borderRadius: '0',
      '& > div:last-child': {
        margin: '1rem',
        padding: '0',
        width: '100%',
      }
    }
  },
  cookiesButton: {
    color: '#2B0096',
    fontSize: '1.4rem',
    fontWeight: 700,
    border: `2px solid #2B0096`,
    textTransform: 'inherit',
  }
}));

interface Props {
  cookieName?: string;
}

const CookiesInfo = (props:Props) => {
  const DEFAULT_COOKIE_NAME:string = 'cookieAccepted';
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {cookieName} = props;

  const handleClose = () => {
    setOpen(false);
  };

  const buttonFunction = () => {
    localStorage.setItem(cookieName?cookieName:DEFAULT_COOKIE_NAME, 'true');
    handleClose();
  };

  useEffect(() => {
    if (!window.matchMedia('(display-mode: standalone)').matches || !(window as any).navigator.standalone) {
      setTimeout(() => setOpen(!localStorage.getItem(cookieName?cookieName:DEFAULT_COOKIE_NAME)), 1500);
    }
  },[]);

  

  return (
    <Snackbar
      className={classes.cookiesCard}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={null}
      message={t('cookies_msg')}
      action={[
        (
          <Button
            className={classes.cookiesButton}
            key="button"
            color="secondary"
            fullWidth
            onClick={buttonFunction}
          >
            {t('accept')}
          </Button>
        )
      ]}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    />
  );
};

export default CookiesInfo;
