import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import ProductCard, { ProductCardData, ProductCardProps } from './ProductCard';
import { ProductOffer } from 'model/interfaces/Model';

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
  },
  inner: {    
    flex: "0 1 auto",
    alignSelf: "auto"
  },
  img: {
    width: "10vmin",
    borderRadius: 5
  },
  text: {
    marginLeft: 10,
    color: '#ffffff'
  }
}));

interface Props {  
  msg?: string,
  products:ProductCardProps[]
}

export const ProductRow = (props:Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {msg, products} = props;

  const handleClose = () => {
    setOpen(false);
  };

  const buttonFunction = () => {
    
  };

  useEffect(() => {    
  },[]);

  

  return (
    <div className={classes.root}>      
      {products.map((item:ProductCardProps, i:number)=>(
        <div key={item.product.sku} className={classes.inner}>
        <ProductCard  product={item.product} onOrdered={item.onOrdered}></ProductCard>
        </div>
        )
      )}        
    </div>
          
            
  );
};
