import React from 'react';
import {    
    useLocation
  } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ProductCardData } from './ProductCard';
import { PayPalButton } from 'react-paypal-button-v2';
import ProductSummary from './ProductSummary';
import { TypographyH2 } from 'components/core/BaseTypography';
import { ProductOffer } from 'model/interfaces/Model';
import { Typography } from '@material-ui/core';
import Loading from 'components/info/loading/Loading';
import SpinnerLarge from 'components/info/loading/SpinnerLarge';

export interface PaymentDialogData {
    product?: ProductOffer,
    show:boolean,
    onCancel: any,
}

export default function PaymentDialog(props:PaymentDialogData) {
  const {product, show, onCancel} = props;

  const [recipientAddress, setRecipientAddress] = React.useState<string>('');
  const [recipientConfirmAddress, setRecipientConfirmAddress] = React.useState<string>('');

  const [showCheckout, setShowCheckout] = React.useState<boolean>(false);
  const [showTYP, setShowTYP] = React.useState<boolean>(false);

  const [busy, setBusy] = React.useState<boolean>(false);

  const [typOrderId, setTypOrderId] = React.useState<string>('');
  const [typProduct, setTypProduct] = React.useState<string>('');
  

  const handleDismiss = () => {
    setShowTYP(false);
    setRecipientAddress('');
    setRecipientConfirmAddress('');
    setShowCheckout(false);
    onCancel();
  };

  const handleRecipientChange = (evt:any) => {
    setRecipientAddress(evt.target.value);    
  };
  const handleRecipientConfirmChange = (evt:any) => {
    setRecipientConfirmAddress(evt.target.value);    
  };

  React.useEffect(() => {
    if(recipientAddress == recipientConfirmAddress && recipientAddress.length>0){
        setShowCheckout(true);
        setBusy(true);
    }else{
        setShowCheckout(false);
    }
        
  }, [recipientAddress, recipientConfirmAddress]);
  
  return (
    <div>
        
      <Dialog open={show} onClose={onCancel} aria-labelledby="form-dialog-title" fullWidth={true}>
        <DialogTitle id="form-dialog-title">{showTYP?'Payment Success':'Transaction Summary'}</DialogTitle>
        <DialogContent>
            {showTYP?<TypographyH2 msg="Order is being processed" dark={true}></TypographyH2>:null}
            {showTYP?<>
            <DialogContentText>              
            Your YTN coin order was registered successfully. Your coins are on their way. It may take up to 2 working hours for the payment to be processed by our payment team. 
            </DialogContentText>
            <DialogContentText>              
            ORDER ID: {typOrderId}
            </DialogContentText>
            </>
            
            :null
            // <DialogContentText>              
            //   Please review your transaction order carefully. Make sure that <strong>recipient YTN address</strong> is valid as when the transaction is done it is an irreversible operation.            
            // </DialogContentText>
          }
          <TypographyH2 msg={showTYP?'Order summary':'Products in cart'} dark={true}></TypographyH2>
          <ProductSummary product={product!}></ProductSummary>
          {showTYP?null:<>
            <TypographyH2 msg="Destination wallet" dark={true}></TypographyH2>
          <TextField
          id="standard-full-width"
          label="Wallet address"
          placeholder="Starting with Y letter"
          helperText="The coins will be sent to this address"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          value={recipientAddress}
          onChange={handleRecipientChange}
        />
        {showCheckout?null:<TextField
          id="standard-full-width2"
          label="Retype wallet address"
          placeholder="Starting with Y letter"
          helperText="For security reason please retype wallet address. Address must match."
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          value={recipientConfirmAddress}
          onChange={handleRecipientConfirmChange}          
        />}
        
        {showCheckout?<>
        <TypographyH2 msg="Proceed to payment" dark={true}></TypographyH2>
        <Typography variant="body1" gutterBottom>
          Select payment method:
        </Typography>
        {busy?<SpinnerLarge></SpinnerLarge>:null}        
        <PayPalButton amount={product!.price.priceFormatted} options={{
            clientId: "AUeRqByIhZnuc-ILASoOVAcgrKplReuD7_96_ujG1xTWjR4PCuLABT2bEplUCnT-pzolPYMfujFi6dx_",
            currency: product!.price.currencyCode            
          }} 
          onButtonReady={()=>{setBusy(false)}}
          createOrder={(data:any, actions:any) => {
            const order = {
                purchase_units: [{
                  amount: {
                    currency_code: product!.price.currencyCode,
                    value: product!.price.priceFormatted
                  },
                  description: `${product?.description.order} coins sent to: ${recipientAddress}`,
                  custom_id: `${product?.description.order}-${recipientAddress}`
                }],
                application_context: {
                  shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                }
              }
            // console.log(order);
            // console.log(data);

            return actions.order.create(order);
          }}
          onSuccess={(details:any, data:any) => {
            // console.log('Details', details);
            // console.log('data', data);
            setTypOrderId(details.id);
            setTypProduct(details.purchase_units[0].description);
            setShowTYP(true);
            // // OPTIONAL: Call your server to save the transaction
            // return fetch("/paypal-transaction-complete", {
            //   method: "post",
            //   body: JSON.stringify({
            //     orderId: data.orderID
            //   })
            // });
          }}
          onError={(err:any)=>{              
            alert("There was an error processing transaction. Please review your payment data and retry. Reason: " + err);
            onCancel();
            // console.log("Error", err);
          }}          
          ></PayPalButton>
          
          </>
        :<>
        <TypographyH2 msg="Proceed to payment" dark={true}></TypographyH2>
        
        <Typography variant="body1" gutterBottom>
          Please complete Destination wallet section for available payment methods.
        </Typography>
        </>
        }
          </>}
          
          
        </DialogContent>
        <DialogActions>
            {showTYP?
            <Button onClick={handleDismiss} color="primary">
            Dismiss
            </Button>
            :
            <Button onClick={onCancel} color="primary">
            Cancel
            </Button>
          }
          
          
        </DialogActions>
      </Dialog>
      
    </div>
  );
}
