import Dummy from 'views/Dummy'


// Routes object.
// Items with menuOrder 0 don't appear in menu

// Role:
//        1 - Allow only for not logged in
//        2 - Allow only for logged in
//        3 - Allow all users

export const Routes = [
  {
    path: '/',
    menuOrder: 0,
    exact: true,
    component: Dummy,
    translation: '',
    role: 3,
  },
  {
    path: '/login',
    menuOrder: 0,
    exact: true,
    component: Dummy,
    translation: '',
    role: 1,
  },
  {
    path: '/reset-password',
    menuOrder: 0,
    exact: true,
    component: Dummy,
    translation: '',
    role: 1,
  },
  {
    path: '/reset-password-finish',
    menuOrder: 0,
    exact: true,
    component: Dummy,
    translation: '',
    role: 1,
  },
  {
    path: '/sign-up/',
    menuOrder: 0,
    exact: true,
    component: Dummy,
    translation: '',
    role: 1,
  },
  {
    path: '/sign-up/:terms',
    menuOrder: 0,
    exact: true,
    component: Dummy,
    translation: '',
    role: 1,
  },
  {
    path: '/sign-up-finish',
    menuOrder: 0,
    exact: true,
    component: Dummy,
    translation: '',
    role: 1,
  },
  {
    path: '/leave/amount',
    menuOrder: 0,
    exact: true,
    component: Dummy,
    translation: '',
    role: 2,
  },
  {
    path: '/leave/typ',
    menuOrder: 0,
    exact: true,
    component: Dummy,
    translation: '',
    role: 2,
  },
  {
    path: '/start',
    menuOrder: 1,
    exact: true,
    component: Dummy,
    translation: 'start',
    role: 2,
  },
  {
    path: '/leave/place',
    menuOrder: 2,
    exact: true,
    component: Dummy,
    translation: 'give_to_jar',
    role: 2,
  },
  {
    path: '/leave/bycode',
    menuOrder: 3,
    exact: true,
    component: Dummy,
    translation: 'give_by_code',
    role: 2,
  },
  {
    path: '/share',
    menuOrder: 4,
    exact: true,
    component: Dummy,
    translation: 'shareWithFriends',
    role: 2,
  },
  {
    path: '/profile',
    menuOrder: 5,
    exact: true,
    component: Dummy,
    translation: 'profileTitle',
    role: 2,
  },
  {
    path: '/logout',
    menuOrder: 99,
    exact: true,
    component: Dummy,
    translation: 'logout',
    role: 3,
  },
  {
    path: '/activate',
    menuOrder: 0,
    exact: true,
    component: Dummy,
    translation: 'logout',
    role: 3,
  },
];


