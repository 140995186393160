import React, { useContext, useState } from 'react';
import UserConsumer from 'model/context/user/UserContext';
import { useTranslation } from 'react-i18next';
import { withRouter, NavLink, RouteComponentProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CloseIcon from '@material-ui/icons/Close';
import styles from './AppHeader.module.scss';
import { Routes } from 'config/Routes';
import { UserData } from 'model/interfaces/UserData';

const useStyles = makeStyles(() => ({
  header_appBar: {
    backgroundColor: 'white',
  },
  header_toolBar: {
    justifyContent: 'space-between',
    minHeight: '5.4rem',
  },
  header_menuIcon: {
    fontSize: '3rem',
    color: '#404040',
  },
  header_menuClose: {
    margin: '0 0.5rem 0 0',
    padding: '1.2rem',
    position: 'absolute',
    justifySelf: 'end',
  },
  header_drawer: {
    '& .MuiDrawer-paper': {
      width: '82%',
      maxWidth: '30rem',
      display: 'grid',
      gridTemplateColumns: 'auto 6rem',
      alignItems: 'start',
    },
    '& .MuiBackdrop-root': {
      background: 'transparent',
    },
  },
}));

// Props types
interface Props {
  showMenu: boolean;
  appName: string;
}

const AppHeader = (props: Props & RouteComponentProps) => {
  const { showMenu, appName } = props;
  const userConsumer = useContext(UserConsumer);  
  const classes = useStyles();
  const {device}:UserData = userConsumer;
  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);

  const toggleMenu = () => setMenu(!menu);

  const HeaderMenu = () => (
    <IconButton
      edge="end"
      color="primary"
      aria-label="menu"
      onClick={toggleMenu}
    >
      <MenuIcon className={classes.header_menuIcon} />
    </IconButton>
  );

  const MenuBuilder = () =>
    Routes.sort((a, b) => (a.menuOrder - b.menuOrder)).map((route, index) => {
      if (route.menuOrder) {
        return (
          <li key={index} className={styles.header_menuItems__item}>
            <NavLink
              exact={route.exact}
              className={styles.header_menuItems__link}
              activeClassName={styles.header_menuItems__linkActive}
              to={route.path}
              onClick={toggleMenu}
            >
              {t(route.translation)}
            </NavLink>
          </li>
        );
      }
      return null;
    });

  return (
    <AppBar
      className={classes.header_appBar}
      position="static"
      elevation={0}
    >
      <Toolbar className={classes.header_toolBar}>
        <NavLink
          exact
          to="/start"
          className={styles.header_logo}
        >          
          <div className={styles.header_logoTitle}>{appName}</div>
        </NavLink>
        {
          showMenu && <HeaderMenu />
        }

        <SwipeableDrawer
          className={classes.header_drawer}
          elevation={0}
          anchor="right"
          open={menu}
          onClose={toggleMenu}
          onOpen={() => null}
          disableBackdropTransition={!device?.properties.isIOS}
          disableDiscovery={device?.properties.isIOS}
        >
          <ul className={styles.header_menuItems}>
            {MenuBuilder()}
          </ul>
          <IconButton
            className={classes.header_menuClose}
            aria-label="close"
            onClick={toggleMenu}
          >
            <CloseIcon className={classes.header_menuIcon}/>
          </IconButton>
        </SwipeableDrawer>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(AppHeader);
