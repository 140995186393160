import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Flag from 'react-world-flags'
import { Link } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        fontSize: 24,
        color: '#ffffff'
    },


}));

interface Props {
    code: string,
    label: string,
    countryCode: string,
    onClick: any
}

export const FlagLink = (props: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const { code, label, onClick, countryCode } = props;

    const handleClick = () => {
        if (onClick)
            onClick(countryCode);
    };



    useEffect(() => {
    }, []);



    return (
        <div style={{ marginTop: 5, marginBottom: 2 }}><Link href="#" onClick={handleClick}><Flag code={code} height="30" style={{ verticalAlign: 'middle' }} /> <span className={classes.root} style={{ verticalAlign: 'middle' }}>{label}</span></Link></div>

    );
};
