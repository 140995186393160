import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { PayPalButton } from "react-paypal-button-v2";
import { Button } from '@material-ui/core';
import ProductCard from 'components/payment/ProductCard';
import { ProductRow } from 'components/payment/ProductRow';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inner: {
    width: "100%"
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: '25ch',
  },
}));

interface Props {  
  children?:any
}

const FormContainer = (props:Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {children} = props;

  const handleClose = () => {
    setOpen(false);
  };

  const buttonFunction = () => {
    
  };

  useEffect(() => {    
  },[]);

  

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.inner}>
      {children}
      {/* <TextField
          id="standard-full-width"
          label="Label"
          placeholder="Placeholder"
          helperText="Full width!"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        /> */}
        {/* <PayPalButton amount="3.00" options={{
          clientId: "AUeRqByIhZnuc-ILASoOVAcgrKplReuD7_96_ujG1xTWjR4PCuLABT2bEplUCnT-pzolPYMfujFi6dx_",
          currency: 'EUR'
        }} shippingPreference="NO_SHIPPING"
        onSuccess={(details:any, data:any) => {
          console.log('Details', details);
          console.log('data', data);
          alert("Transaction completed by " + details.payer.name.given_name);

          // // OPTIONAL: Call your server to save the transaction
          // return fetch("/paypal-transaction-complete", {
          //   method: "post",
          //   body: JSON.stringify({
          //     orderId: data.orderID
          //   })
          // });
        }}
        onError={(err:any)=>{
          console.log("Error", err);
        }}
        ></PayPalButton> */}
        
        {/* <Button variant="contained">Buy 150 YTN</Button>
        <Button variant="contained">Buy 320 YTN</Button>
        <Button variant="contained">Buy 700 YTN</Button> */}
        {/* <TextField
          label="Dense"
          id="margin-dense"
          defaultValue="Default Value"
          className={classes.textField}
          helperText="Some important text"
          margin="dense"
        />
        <TextField
          label="Normal"
          id="margin-normal"
          defaultValue="Default Value"
          className={classes.textField}
          helperText="Some important text"
          margin="normal"
        /> */}
      </div>
    </form>
  );
};

export default FormContainer;
