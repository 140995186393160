import React from 'react';
import { UserData } from 'model/interfaces/UserData';
import * as deviceDetect from 'react-device-detect';

const UserContext = React.createContext<UserData>({
  isLoggedIn: false,
  userId: '',  
  email: '',
  userName: '',
  device: {
    userAgent: navigator.userAgent,
    properties: deviceDetect
  }      
});

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;
export default UserContext;
