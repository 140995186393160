import { backendApi } from "integration/backendApi";
import { ProductDescription, ProductOffer } from "model/interfaces/Model";

class ATMManager {
    
    // products:Map<string,Map<string,ProductOffer>> = new Map(); // sku, currency

    // ratios:any = {
    //     'EUR': 1,
    //     'USD':1.3,
    //     'RUB':93
    // }

    // eurUnitPrice:number = 0.016;
    
    // // (new Map<string,ProductDescription>()).setSKU.YTN.300

    // // constructor(){
    // //     const skuYtn300 = new Map<string,ProductOffer>();
    // //     skuYtn300.set('USD',{
    // //         amount:300,
    // //         description: {
    // //             invoice: ''
    // //         },
    // //         name: 'YTN Coins',
    // //         sku: 'SKU.YTN.300',
    // //         price: {},
    // //         unitPrice: {}
    // //     })
    // // }

    // _currencyToSign(currency: string){
    //     let sign = '';
    //     switch (currency.toUpperCase()) {
    //         case 'EUR':
    //             sign = '€'
    //             break;
    //         case 'USD':
    //             sign =  '$'
    //             break;
    //         case 'RUB':
    //             sign = '₽'
    //             break;                
    //         default:
    //             sign = '€'
    //             break;
    //     }
    //     return sign;
    // }

    // /**
    //  * 
    //  * @param sku 
    //  * @param amount satoshis
    //  * @param currency 
    //  * @param eurPrice 1coin price (1e8 sat)
    //  * @param exchangeRatio 
    //  */
    // _createOffer(sku: string, amount:number, currency: string, eurPrice:number, exchangeRatio:number){
    //     let offer:ProductOffer = {
    //         amount: amount,
    //         description: {
    //             invoice: `YTN coins (${amount/1e8} x 1pcs)`,
    //             order: `YTN coins (${amount/1e8} x 1pcs)`,
    //             short: 'Yenten (YTN) coins',
    //             long: 'Delivered to provided YTN wallet address',
    //             shipping: 'Wallet transfer (digital)'
    //         },
    //         name: 'Yenten Coins (YTN)',
    //         sku: sku,
    //         unitPrice: {
    //             currencyCode: currency.toUpperCase(),
    //             price: eurPrice*exchangeRatio,
    //             priceFormatted: (eurPrice*exchangeRatio*1e-2).toFixed(2),
    //             priceFullFormatted: `${this._currencyToSign(currency)}${(eurPrice*exchangeRatio*1e-2).toFixed(2)}`
    //         },
    //         price: {
    //             currencyCode: currency.toUpperCase(),
    //             price: eurPrice*exchangeRatio*amount,
    //             priceFormatted: (eurPrice*exchangeRatio*amount*1e-8).toFixed(2),
    //             priceFullFormatted: `${this._currencyToSign(currency)}${(eurPrice*exchangeRatio*amount*1e-8).toFixed(2)}`
    //         }
    //     }
    //     return offer;
    // }

    getOffer(amount:number, currency: string):Promise<ProductOffer|undefined>{
        return backendApi.getOffers().then((offers:ProductOffer[])=>{
            let matching:ProductOffer|undefined = offers.find((item:ProductOffer)=>{
                return item.currency.toUpperCase() == currency.toUpperCase() && item.amount == amount
            })
            return matching;            
        })
        // const offer = this._createOffer('SKU.YTN.'+(amount/1e8).toFixed(2), amount, currency, this.eurUnitPrice,this.ratios[currency.toUpperCase()]);
        // // console.log('OFFER', offer);
        // return Promise.resolve(offer);
    }

    getOffers(currency: string):Promise<ProductOffer[]|undefined>{
        return backendApi.getOffers()
        .then((offers:ProductOffer[])=>{            
            return offers.filter((item:ProductOffer)=>{
                return item.currency.toUpperCase() == currency.toUpperCase()
            })            
        })                      
    }
}

export const atmManager = new ATMManager();
