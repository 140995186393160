import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { TypographyH1 } from './BaseTypography';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 20,
    paddingBottom: 10,
    flexGrow: 1,
    backgroundColor: "transparent",    
  },
  inner: {    
    borderRadius: 4,
    // border: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "transparent",
    minWidth: "40vw"
  },
  img: {
    width: "20vw"
  }
}));

interface Props {  
  children?:any,
  style?:any, 
}

const Container = (props:Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {children,style} = props;
  


  const handleClose = () => {
    setOpen(false);
  };

  const buttonFunction = () => {
    
  };

  useEffect(() => {    
  },[]);

  

  return (
    <Grid container style={style?style.root:null} className={classes.root} spacing={2} justify="center">
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
        <Grid style={style?style.inner:null}  className={classes.inner}  item >          
        {children}
        </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Container;
