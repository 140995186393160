import * as firebase from 'firebase';
import 'firebase/functions';
import { ProductOffer } from 'model/interfaces/Model';

class BackendAPI {
    _call(name:string, dto?: any):Promise<any>{
        const callable = firebase.functions().httpsCallable(name)
        let request:any = {            
            // ctx: {                
            // }
        }
        if(dto){
            request.dto = dto
        }

        return callable(request).then((result:any)=>{
            return result.data;
        })
    }
    /**
     * Get configured offers
     */
    getOffers():Promise<ProductOffer[]>{
        return this._call('catalogGetOffers');
    }
}

export const backendApi = new BackendAPI();
