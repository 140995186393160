import React from 'react';
import styles from './SpinnerLarge.module.scss'; 

const SpinnerLarge = () => {
	return (
		<div className={styles['lds-spinner-large']}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
	);
}

export default SpinnerLarge;
