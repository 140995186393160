import i18n from 'i18next';
import { initReactI18next  } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Polish translation file
import { pl } from 'config/i18n/lang/pl';
// English translation file
import { en } from 'config/i18n/lang/en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: en,
        name: 'English',
      },
      pl: {
        translation: pl,
        name: 'Polski',
      },
    },
    react: {
      wait: true,
    },
  });

// You can change language by e.g. onClick={() => i18next.changeLanguage('en')}

export default i18n;
