import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { TypographyH2, TypographyH3 } from './BaseTypography';

const useStyles = makeStyles(() => ({  
  footer: {

    boxSizing: 'border-box',
    minHeight: '10vmin',
    backgroundColor: '#eee',
    borderTop: '1px solid #e0e0e0',
    paddingLeft: 10,
    paddingRight: 10
  },
  inner: {    
    
    paddingTop: 5
  },
  innerLast: {    
    flex: "1 1 auto",
    alignSelf: "auto"    
  },
  img: {
    width: '5vmin',
    height: '5vmin',    
  },
  links: {
    fontSize: '9px',
    paddingTop: 3,
    paddingBottom: 2
  }
  
}));

interface Props {    
}

export const Footer = (props:Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {} = props;

  const handleClose = () => {
    setOpen(false);
  };

 

  useEffect(() => {    
  },[]);

  

  return (
    <footer>
      <Grid className={classes.footer}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid className={classes.inner} item xs={12} sm={3}>
        <TypographyH3 msg="©2021 YentenObserver and it's affiliates" showIcon={true} dark={true}></TypographyH3>
        <div className={classes.links}>Made with ♥ by <a href="https://yentenobserver.medium.com/">Yenten Observer</a></div>
        </Grid>
        <Grid className={classes.inner} item xs={12} sm={3}>
        
        <div className={classes.links}>DISCUSS &amp; PARTICIPATE</div>
        <div className={classes.links}>Yenten Community @<a href="https://discord.gg/6rBk8ZmSYJ">Discord</a></div>
        <div className={classes.links}>Yenten Community @<a href="https://www.reddit.com/r/Yenten/">Reddit</a></div>        
        <div className={classes.links}>Twitter <a href="https://twitter.com/ObserverYenten">@ObserverYenten</a></div>
        <div className={classes.links}>YouTube <a href="https://www.youtube.com/channel/UCBSUO0iDqyeP9ISKKK1X8_Q">YentenObserver YouTube channel</a></div>  
        <div className={classes.links}>Support <a href="mailto:support@yenten.io">support@yenten.io</a></div>        
        
        </Grid>
        <Grid className={classes.inner} item xs={12} sm={3}>
        
        <div className={classes.links}>USE</div>
        
        <div className={classes.links}><a href="https://play.google.com/store/apps/details?id=ml.yvault.app">Yenten Mobile Wallet</a></div>
        <div className={classes.links}><a href="https://ytn.ccore.online/web_wallet">Yenten Web Wallet</a></div>
        <div className={classes.links}><a href="https://github.com/yentencoin/yenten/releases/download/4.0.3/yenten-4.0.3.1-linux64.tar.gz">Yenten Linux 4.0.3</a></div>        
        <div className={classes.links}><a href="https://github.com/yentencoin/yenten/releases/download/4.0.3/yenten-4.0.3.1-win32.zip">Yenten Windows 4.0.3</a></div>        
        <div className={classes.links}><a href="https://github.com/yentencoin/yenten/releases/download/4.0.3/Yenten-Core-4.0.3.1.dmg">Yenten MacOs 4.0.3</a></div>                
        
        </Grid>
        <Grid className={classes.inner} item xs={12} sm={3}>
        
        <div className={classes.links}>LEARN</div>
        <div className={classes.links}>Learn more about Yenten at <a href="https://yentencoin.info/">YentenCoin hompage</a></div>
        <div className={classes.links}>Check news and announcements <a href="https://twitter.com/yentencoin/">@yentencoin</a></div>        
        <div className={classes.links}>Read Yenten history at <a href="https://bitcointalk.org/index.php?topic=5098631">Bitcointalk</a></div>
        <div className={classes.links}>Yenten news &amp; articles <a href="https://yentenobserver.medium.com/">Yenten Observer@Medium</a></div>
        <div className={classes.links}>Community Yenten Page <a href="https://yenten.org/">yenten.org</a></div>
        
        </Grid>

      </Grid>      
    </footer>                     
  );
};
