import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 5
  },
  inner: {    
    flex: "0 1 auto",
    alignSelf: "auto"
  },
  img: {
    width: "10vmin",
    borderRadius: 5
  },
  imgSmall: {
    width: "5vmin",
    borderRadius: 2
  },
  text: {
    marginLeft: 10,
    color: '#ffffff'
  },
  
  textDark: {
    marginLeft: 10,  
    color: '#263571'    
  },
  textDarkH3: {
    marginLeft: 10,  
    color: '#263571',
    fontSize: '1vh'   
  },
  textH3: {
    marginLeft: 10,
    color: '#ffffff',
    fontSize: '1vh'   
  },
  cite: {
    '&::before': {
      content: '",,"',
      display: 'inline',
      height: 60,
      fontSize: '30px',
      marginTop: 0
    }    
  }
  
}));

interface Props {  
  msg: string,
  showIcon?:boolean,
  dark?:boolean
}

export const TypographyH1 = (props:Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {msg, showIcon} = props;

  const handleClose = () => {
    setOpen(false);
  };

  const buttonFunction = () => {
    
  };

  useEffect(() => {    
  },[]);

  

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
      {showIcon?<img className={classes.img} src={require('../../assets/yenten-observer-logo.svg')}></img>:null}
        
      </div>
      <div className={classes.inner}>
      <Typography variant="h4" gutterBottom className={classes.text}>
        {msg}
        </Typography>
        </div>
    </div>                      
  );
};

export const TypographyH2 = (props:Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {msg, showIcon, dark} = props;

  const handleClose = () => {
    setOpen(false);
  };

  const buttonFunction = () => {
    
  };

  useEffect(() => {    
  },[]);

  

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
      {showIcon?<img className={classes.imgSmall} src={require('../../assets/yenten-observer-logo.svg')}></img>:null}
        
      </div>
      <div className={classes.inner}>
      <Typography variant="h6" gutterBottom className={dark?classes.textDark:classes.text}>
        {msg}
        </Typography>
        </div>
    </div>                      
  );
};


export const TypographyH3 = (props:Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {msg, showIcon, dark} = props;

  const handleClose = () => {
    setOpen(false);
  };

  const buttonFunction = () => {
    
  };

  useEffect(() => {    
  },[]);

  

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
      {showIcon?<img className={classes.imgSmall} src={require('../../assets/yenten-observer-logo.svg')}></img>:null}
        
      </div>
      <div className={classes.inner}>
      <Typography variant="h6" gutterBottom className={dark?classes.textDarkH3:classes.textH3}>
        {msg}
        </Typography>
        </div>
    </div>                      
  );
};

export const TypographyCitation = (props:Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {msg, showIcon, dark} = props;

  const handleClose = () => {
    setOpen(false);
  };

  const buttonFunction = () => {
    
  };

  useEffect(() => {    
  },[]);

  

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
      {showIcon?<img className={classes.imgSmall} src={require('../../assets/yenten-observer-logo.svg')}></img>:null}
        
      </div>
      <div className={classes.inner}>
      <Typography variant="h4" gutterBottom className={`${classes.text} ${classes.cite}`}>
        {msg}
        </Typography>
        </div>
    </div>                      
  );
};
