export const pl = {
  application_name: 'PayTip',
  intro_text: 'Zawsze w Twoim telefonie szybko i wygodnie',
  login_title: 'Logowanie',
  email: 'E-mail',
  password: 'Hasło',
  email_placeholder: 'Podaj e-mail',
  password_placeholder: 'Podaj hasło',
  login: 'Zaloguj',
  forgot_password: 'Nie pamiętam hasła',
  wrong_data: 'Błędne dane',
  login_facebook: 'Zaloguj przez facebook',
  dont_have_account: 'Nie masz jeszcze konta PayTip?',
  sign_up: 'Zarejestruj się',
  sign_in: 'Zaloguj się',
  reset_password_title: 'Resetowanie hasła',
  reset_password_help: 'Podaj e-mail powiązany z Twoim kontem, a prześlemy tam link do zmiany hasła.',
  reset_password_info: 'Link do zmiany hasła został wysłany.',
  reset_password: 'Resetuj hasło',
  new_update: 'New version has arrived! Reload your app.',
  reload: 'Reload',
  please_verify_email: 'Proszę zweryfikuj swój email',
  currency: 'zł',
  cookies_msg: 'We may store and access personal data such as cookies, device identifiers or other similar technologies on your device and process such data to personalise content and analyse our traffic. You can read more about this and how to object by Privacy Policy.',
  accept: 'OK',
  errMessage: 'Ups, coś poszło nie tak. Spróbuj ponownie',
  errMessage_blik: 'Ups, sprawdź czy wprowadziłeś poprawny kod BLIK i spróbuj ponownie',
  errMessage_maxTip: 'Maksymalna wysokość napiwku to',
  errMessage_minTip: 'Minimalna wysokość napiwku to',
  giveTip: 'Wręczenie napiwku',
  invoice_amount: 'Kwota rachunku',
  tipPlaceholder: 'Np.: 29.00',
  valueOfTipHeight: 'Podaj kwotę napiwku',
  entry: 'Dalej',
  paymentType: 'Płatność',
  choosePaymentType: 'Wybierz formę płatności',
  writeBlikCode: 'Podaj kod BLIK',
  blikCodePlaceholder: ' 6 cyfr',
  pay: 'Wręczam',
  pay_retry: 'Próbuję ponownie',
  errMessage_Payment: 'Upss, coś poszło nie tak. <br/><br/>Może autoryzacja w banku, albo limity…',
  tip_is_ready: 'Świetnie !<br/>napiwek gotowy',
  give_qrcode: 'Poproś odbiorcę o zeskanowanie kodu QR aparatem.',
  error: 'Błąd',
  give_next_tip: 'Do zobaczenia wkrótce',
  in: 'Za',
  countdown_text: 'zostaniesz automatycznie przekierowany/a na widok początkowy, gdzie będziesz mógł/a wręczyć kolejny napiwek.',
  information: 'Informacja',
  blik_info1: 'Przejdź do Twojej aplikacji bankowej i wygeneruj kod BLIK',
  blik_info2: 'Potwierdź kod BLIK w Twojej aplikacji bankowej.',
  blik_info_first_step: 'Prosimy o chwilę cierpliwości, napiwek już płynie...',
  desktop_dialog_title: 'Przejdź na urządzenie mobilne',
  desktop_dialog_content: 'Aplikacja jest przeznaczona na urządzenia mobilne. Zeskanuj kod QR za pomocą swojego smartfona.',
  home: {
    heroAlt: 'Witaj w PayTip',
    helloName: 'Witaj,',
    helloNoName: 'Witaj!',
    leavePlace: 'Wrzuć do</br>słoika',
    leaveQR: 'Wręcz<br/>QR kodem',
    share: 'Poleć<br/>znajomym',
    profile: 'Mój profil<br/>&nbsp;'
  },
  activate: {
    titleSuccess: 'Gratulacje!',
    title: 'Aktywacja konta',
    email: 'Email',
    emailPlaceholder: 'Podaj swój adres email',
    code: 'Kod aktywacyjny',
    codePlaceholder: 'Wpisz kod z maila (6 cyfr)',
    proceed: 'Wyślij',
    message: 'Na twojego maila wysłaliśmy kod aktywacyjny. Sprawdź skrzynkę pocztową i wpisz otrzymany kod.',
    message2: 'Jeżeli nie otrzymasz wiadomości w ciągu kilku sekund sprawdź folder Spam.',
    messageSuccess: 'Teraz możesz w pełni korzystać z aplikacji.',
    login: 'Przejdź do logowania'
  },
  share: {
    message: 'Cześć! Czy korzystasz z PayTip, gdy wręczasz napiwek? Polecam - apka jest świetna! https://giver.paytip.pl',
    messageSubject: 'Napiwki z telefonu',
    heroAlt: 'Help PayTip grow',
    heroTitle: 'Pomóż rozwijać PayTip!',
    heroMsg: 'Zaproś znajomych do PayTip i demokratyzuj z nami napiwki. Razem możemy więcej !',
    methodTitle: 'Wybierz',
    methodSubtitle: 'jak chcesz powiedzieć znajomym o PayTip',
    infoText: 'Osoby zaproszone do PayTip otrzymają krótką wiadomość zachęcającą do skorzystania z aplikacji.',
  },
  leave: {
    thanks: 'Dzięki za napiwek',
    explainer: 'Właśnie wrzuciłeś tipa do słoika ',
    explainerCtd: 'W imieniu odbiorcy dziękujemy i zapraszamy ponownie, ale ale... <br/><br/>jeżeli masz jeszcze chwilę zostaw opinię i oceń lokal.',
    gotIt: 'Super, rozumiem',
    oneMore: 'jeżeli masz jeszcze chwilę zostaw opinię i oceń lokal.',
    shareOpinion: 'Wyślij opinię',
    sharePlaceholder: 'Tutaj wpisz swoją rekomendację. \nUmieścimy ją na Trip Advisor i Facebook\'u',
    tipboxIntro: 'Słoik',
    reviewThanks: 'Dziękujemy! Opinia zarejestrowana. Wkrótce będzie ona dostępna na portalu TripAdvisor i Facebook.',
    ratingLabel: 'Twoja ocena lokalu',
    reviewHosNameIntro: 'Osoba obsługująca: ',
  },
  cityDictionary: {
    warsawAndSuburbs: 'Warszawa i okolice',
    poznan: 'Poznań',
    krakow: 'Kraków'
  },
  place: {
    pageSubTitle: 'Wrzuć do słoika',
    citySelectTitle: 'Miasto',
    citySelectSubTitle: 'Gdzie jesteś?',
    placeFinderTitle: 'Lokal',
    placeFinderSubTitle: 'Gdzie chcesz zostawić napiwek?',
    placeFinderPlaceholder: 'Wpisz nazwę lokalu',
    nextStep: 'Podaj kwotę',
    tipboxInfo: 'Napiwek trafi do słoika we wskazanym lokalu. Obsługa odbierze tipa w dogodnym dla siebie momencie.',
    noResults: 'Zacznij wpisywać nazwę aby wyszukać lokal'
  },
  profileTitle: 'Profil',
  profile: {
    name: 'Imię',
    namePlaceholder: 'Twoje imię',
    nameInvalidMsg: 'Podaj swoje imię',
    phone: 'Numer telefonu',
    phonePlaceholder: 'np.: 501000100',
    phoneInvalidMsg: 'Podaj swój numer telefonu (9 cyfr)',
    save: 'Zapisz',
    whyData: 'Zostaw numer telefonu. Będziesz mógł szybciej odblokować aplikację w przypadku nieautoryzowanego dostępu.',
    whyName: 'W PayTip wszyscy mówimy sobie po imieniu, bo razem możemy zdemokratyzować napiwki.',
    operationOk: 'Sukces! Dane zapisane.',
    operationErr: 'Uuuups! Coś poszło nie tak. Sprawdź poprawność danych i spróbuj ponownie.',
  },
  give: {
    title: 'Wręcz napiwek QR kodem',
    explainer: 'Poproś obsługę o przygotowanie telefonu. W kolejnych krokach aplikacja wygeneruje kod QR zawierający napiwek. Zaproponuj odbiorcy zeskanowanie kodu aparatem.',
    explainer2: 'Jeżeli odbiorca nie może teraz zeskanować kodu QR, wrzuć <a href="/leave/place">napiwek do słoika</a>; będzie tam czekał na odbiór.',
    amountTitle: 'Podaj kwotę napiwku'
  },
  give_to_jar: 'Wrzuć do słoika',
  start: 'Start',
  logout: 'Wyloguj',
  give_by_code: 'Wręcz QR kodem',
  logged_out: 'Wylogowano',
  login_again: 'Zaloguj ponownie',
  error_email: 'Błędny adres email',
  terms_accept_text1: 'Akceptuję',
  terms_accept_text2: 'oraz wyrażam zgodę na przetwarzanie moich danych.',
  terms_accept_link: 'regulamin i politykę prywatności',
  sign_up_title: 'Rejestracja',
  sign_up_info: 'Link aktywacyjny został wysłany na podany adres e-mail.',
  create_account: 'Załóż konto',
  already_have_account: 'Posiadasz już konto?',
  terms: 'Dokumenty prawne',
  terms_text: `
    <div style="margin-bottom: 20px;">
      <div style="display: inline-block; width: 68%;">
        <a style="color: black" href="https://app.paytip.pl/documents/Regulamin_PayTip_-_15.06.2020.pdf">Regulamin PayTip</a><br />
        <span style="font-size: 0.9rem">15 czerwca 2020 roku</span>
      </div>
      <div style="display: inline-block; width: 30%; text-align: right;">
        <img height="50" style="vertical-align: sub" src="https://app.paytip.pl/img/Adobe-PDF-File-Icon-01.png" />
      </div>
    </div>
    <div style="margin-bottom: 20px;">
      <div style="display: inline-block; width: 68%;">
        <a style="color: black" href="https://app.paytip.pl/documents/Polityka_prywatnosci_-_15.06.2020.pdf">Polityka prywatności</a><br />
        <span style="font-size: 0.9rem">15 czerwca 2020 roku</span>
      </div>
      <div style="display: inline-block; width: 30%; text-align: right;">
        <img height="50" style="vertical-align: sub" src="https://app.paytip.pl/img/Adobe-PDF-File-Icon-01.png" />
      </div>
    </div>
  `,
  tipHistory: 'Historia napiwków',
  language: 'Język',
  shareWithFriends: 'Poleć znajomym',
};
