import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import Notification from 'components/info/notification/Notification';
import AppHeader from 'components/core/AppHeader';
import CookiesInfo from 'components/info/cookiesInfo/CookiesInfo';
import AppFrame from 'components/core/AppFrame'

import { useTranslation } from 'react-i18next';

import { UserData } from 'model/interfaces/UserData';
import { UserProvider } from 'model/context/user/UserContext';


import styles from './App.module.scss';

import { initFirebase, config } from 'config/Firebase';

// Initialize firebase
initFirebase();

const App = () => {
  const { t } = useTranslation();
  const [newServiceWorker, setNewServiceWorker] = useState(false);

  const [currentUser, setCurrentUser] = useState<UserData>({
    isLoggedIn: false,
    userId: '',    
    email: '',
    userName: '',    
  });

  // const userAuth = (user: any) => {
  //   const isFacebook = user.providerData.find((provider: {providerId: string}) => provider.providerId === 'facebook.com');
  //   if (isFacebook || user.emailVerified) {
  //     setCurrentUser((d) => ({
  //       ...d,
  //       isLoggedIn: true,
  //       userId: user.uid,
  //       email: user.email,
  //     }));

  //     backendLib.backendLoadUser(user.uid).then((userData: any) => {
  //       if (userData) {
  //         console.log('userData', userData);
  //         let phoneItem : { m: string } = { m: '' };
  //         if (userData.uc) {
  //           phoneItem = userData.uc.find((item: any) => item.t = 'P');
  //         }
  //         setCurrentUser((d) => ({
  //           ...d,
  //           userName: userData.n || '',
  //           userPhone: phoneItem.m,
  //         }));
  //       }
  //       setResponseReady(true);
  //     });
  //   } else {
  //     console.log('Please verify your email!');
  //     setShowVerificationInfo(true);
  //     backendLib.userSignOut();
  //     setResponseReady(true);
  //     setCurrentUser({
  //       isLoggedIn: false,
  //       userId: '',
  //       isIOS,
  //       email: '',
  //       userName: '',
  //       configuration: {
  //         tipMinValue: 0,
  //         tipMaxValue: 0,
  //       },
  //       userPhone: '',
  //     });
  //   }
  // };


  // SPA new version handling
  useEffect(() => {
    // Listen to new version
    window.addEventListener('newContentAvailable', () => {
      // Then show notification
      setNewServiceWorker(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // console.log(config);
  }, []);


  

  return (
    <UserProvider
      value={{...currentUser}}
    >
      <Router>
        <AppHeader
          showMenu={currentUser.isLoggedIn}
          appName="Yenten ATM"
        />
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className={styles.switchWrapper}
        >
          <AppFrame ready={true} loginRedirectPath="/login" startRedirectPath="/start" />  
        </AnimatedSwitch>
      </Router>
      
      {/* here go some extra elements  on top of views generated by route*/}

      { /* Show new service worker notification */
        newServiceWorker &&
        <Notification
          type="update"
          msg={t('new_update')}
          buttonMsg={t('reload')}
          autoHideDuration={null}
          buttonFunction={() => window.location.reload(true)}
        />
      }
      <CookiesInfo />
    </UserProvider>
  );
};

export default App;