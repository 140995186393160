import { Grid } from '@material-ui/core';
import { TypographyH1, TypographyH2 } from 'components/core/BaseTypography';
import Container from 'components/core/Container';
import FormContainer from 'components/core/FormContainer';
import { FlagLink } from 'components/info/FlagLink';
import PaymentDialog from 'components/payment/PaymentDialog';
import { ProductCardProps } from 'components/payment/ProductCard';
import { ProductRow } from 'components/payment/ProductRow';
import { atmManager } from 'logic/ATMManager';
import { ProductOffer } from 'model/interfaces/Model';
import React from 'react';

import { useLocation } from 'react-router-dom';
import styles from './Dummy.module.scss';
import { useHistory } from "react-router-dom";
import Loading from 'components/info/loading/Loading';
const moment = require('moment')

// const EUR_1 = 550;
// const EUR_2 = 1050;
// const USD_M = 1.3;
// const RUB_M = 93;
// const USD_1 = EUR_1*USD_M;
// const RUB_1 = EUR_1*RUB_M;
// const USD_2 = EUR_2*USD_M;
// const RUB_2 = EUR_2*RUB_M;

const Dummy = () => {
  const [showPayment, setShowPayment] = React.useState<boolean>(false);
  const [busy, setBusy] = React.useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = React.useState<ProductOffer>();

  const [offers, setOffers] = React.useState<ProductCardProps[]>([]);

  const handleSelect = (item: ProductOffer) => {
    // console.log('Ordered ', item);
    setSelectedProduct(item);
    setShowPayment(true);
  };

  const handleCancelPayment = () => {
    setShowPayment(false);
  };

  const rebuildProducts = () => {
    let newOffers: ProductOffer[] = [];
    setBusy(true)
    atmManager.getOffers(currency).then((v: ProductOffer[]|undefined)=>{
      newOffers.push(v![0])
      newOffers.push(v![1])
      console.log(newOffers);
      setOffers([
        { onOrdered: handleSelect, product: newOffers[0] },
        { onOrdered: handleSelect, product: newOffers[1] }
      ]);
    })
    .finally(()=>{
      setBusy(false)
    })

    // atmManager.getOffer(300*1e8, currency)
    //   .then((v: ProductOffer|undefined) => {
    //     if(v)
    //       newOffers.push(v);
    //     return atmManager.getOffer(620*1e8, currency);
    //   })
    //   .then((v: ProductOffer|undefined) => {

    //     if(v)
    //       newOffers.push(v);
    //     console.log(newOffers);
    //     setOffers([
    //       { onOrdered: handleSelect, product: newOffers[0] },
    //       { onOrdered: handleSelect, product: newOffers[1] }
    //     ]);
        
    //   })
    //   .finally(()=>{
    //     setBusy(false)
    //   })
  };

  const location = useLocation();
  let history = useHistory();

  const handleChangeCurrency = (code:string) => {
    history.push(`/?c=${code}`);
    setCurrency(code);
  };

  const getQueryParams = (queryString: string, param: string) => {
    return (new URLSearchParams(queryString)).get(param) || undefined;
  }

  const [currency, setCurrency] = React.useState<string>(getQueryParams(location.search, 'c')?.toUpperCase() || 'EUR');

  React.useEffect(() => {
    rebuildProducts();
  }, [currency]);

  React.useEffect(() => {
    rebuildProducts();
  }, []);

  return (
    <div
      className={`${styles.s_wrapper} ${styles.s_container}`}
    >
      <Container>
        <TypographyH1 msg="Yenten ATM" showIcon={true}></TypographyH1>
      </Container>

      {/* <Container style={{
      inner:{
        backgroundColor: "#ffffff"
    }}}>     */}
      <Container>
        <TypographyH1 msg="Purchase YTN coins"></TypographyH1>
        <TypographyH2 msg="Please select requested amount"></TypographyH2>
        <FormContainer>
          <ProductRow products={offers}></ProductRow>
        </FormContainer>        
      </Container>
      <Container>
        <TypographyH2 msg="or Choose preferred currency"></TypographyH2>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item >
            <FlagLink code="EU" label="EUR" countryCode="eur" onClick={handleChangeCurrency}></FlagLink>
          </Grid>
          <Grid item >
            <FlagLink code="RU" label="RUB" countryCode="rub" onClick={handleChangeCurrency}></FlagLink>
          </Grid>
          <Grid item >
            <FlagLink code="US" label="USD" countryCode="usd" onClick={handleChangeCurrency}></FlagLink>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <TypographyH2 msg={`Exchange rates last updated since ${moment(offers[0]?offers[0].product.calculationTS:undefined).format("YYYY/MM/DD HH:mm:ss")}`}></TypographyH2>        
      </Container>
      <Container>
        <div style={{marginTop: 30}}></div>
        
        <div style={{textAlign: 'center'}}>
        {/* <a href="https://www.paypal.com/pl/verified/pal=alkeicam%40yahoo%2ecom" target="_blank">
          <img src="https://www.paypal.com/pl_PL/i/icon/verification_seal.gif" alt="Oficjalna pieczęć PayPal" style={{height: 60, width: 60, margin: 10}}/>
        </a><br/> */}
        <a href="https://www.paypal.com/pl/verified/pal=alkeicam%40yahoo%2ecom" target="_blank">
        <img src={require('../assets/buyer-protection.png')} style={{height: 90,  margin: 10}}></img>
        </a>
        </div>
        <TypographyH2 msg="Guaranteed safe checkout by PayPal Checkout"></TypographyH2>
        <a href="https://www.paypal.com/pl/verified/pal=alkeicam%40yahoo%2ecom" target="_blank"><TypographyH2 msg={`Click to check YentenObserver merchant at PayPal`}></TypographyH2></a>
      </Container>
      <PaymentDialog show={showPayment} onCancel={handleCancelPayment} product={selectedProduct}></PaymentDialog>
      {busy?<Loading></Loading>:null}
    </div>

  );
};

export default Dummy;