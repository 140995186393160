import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';

import Loading from 'components/info/loading/Loading';
import { Routes } from 'config/Routes'
import { UserData } from 'model/interfaces/UserData';
import { Footer } from './Footer';

interface Props {
  user?: UserData;
  startRedirectPath?: string;
  loginRedirectPath?: string;
  ready?: boolean;
}

const AppFrame = (props: Props & RouteComponentProps) => {
  const { user, startRedirectPath, loginRedirectPath, ready } = props;
  const ERROR_PATH: string = '/error';
  useEffect(() => {
  }, []);
  const RoutesBuilder = () => (
    // See Routes.tsx
    //        Roles:
    //        1 - Allow only for not logged in
    //        2 - Allow only for logged in
    //        3 - Allow all users   
    Routes.map((route, index) => {
      // allowed to all users, if possible display
      if (route.role === 3) {
        return (<Route key={index} path={route.path} exact={route.exact} component={route.component} />);
      }

      // allowed to logged in users
      if (route.role === 2) {        
        if (user && user.isLoggedIn) {
          // user logged in so show
          return (<Route key={index} path={route.path} exact={route.exact} component={route.component} />);
        } else {
          // user not logged in, so redirect to login
          return (<Route key={index} path={route.path} exact={route.exact}><Redirect to={loginRedirectPath || ERROR_PATH} /></Route>);
        }
      }
      // allowed only for non logged users
      if (route.role === 1) {        
        if (!user || !user.isLoggedIn)
          return (<Route key={index} path={route.path} exact={route.exact} component={route.component} />);
        else {
          // when user is logged redirect him to start instead of showing him the view
          return (<Route key={index} path={route.path} exact={route.exact}><Redirect to={startRedirectPath || ERROR_PATH} /></Route>);
        }
      }

      


      // if (user && user.isLoggedIn) {
      //   if (route.role === 2) {
      //     return (<Route key={index} path={route.path} exact={route.exact} component={route.component} />);
      //   } else {
      //     return (<Route key={index} path={route.path} exact={route.exact}><Redirect to={startRedirectPath || ERROR_PATH} /></Route>);
      //   }
      // }

      // if (user && !user.isLoggedIn && route.role === 1) {
      //   return (<Route key={index} path={route.path} exact={route.exact} component={route.component} />);
      // } else {
      //   return (<Route key={index} path={route.path} exact={route.exact}><Redirect to={loginRedirectPath || ERROR_PATH} /></Route>);
      // }
    })
  );

  return (
    <>
      {ready ? <>
      {RoutesBuilder()}
      <Footer></Footer>
      </> : <Loading />}
    </>
  )
};

export default withRouter(AppFrame);
